import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BookingToUpdate } from '../../classes/BookingToUpdate';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { GlobalSharedService } from '../../global-shared.service';
import { Booking } from '../../interfaces/Booking';
import { Pagination } from '../../interfaces/Pagination';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  private bookingUrl!: string;
  bookings$ = new BehaviorSubject<Booking[] | undefined>(undefined);
  public showBookingsPageCustomTabs = new FormControl(false);
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) {
    this.bookingUrl = `${this.globalSharedService.config.baseUrl}/bookings`;
  }

  getBookingById(id: string | null | undefined): Observable<Booking> {
    if (!id) return this.errorService.noPropertyError();
  
    return this.http.get<Booking>(`${this.bookingUrl}/${id}`).pipe(
      tap((booking) => {
        const currentBookings = this.bookings$.getValue();
        if (currentBookings) {
          const updatedBookings = currentBookings.map((b) =>
            b.id === booking.id ? booking : b
          );
          this.bookings$.next(updatedBookings);
        }
      }),
      catchError(this.errorService.handleError)
    );
  }

  updateBookingLegs(id: string | undefined, data: BookingToUpdate[]): Observable<unknown> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.patch<unknown>(`${this.bookingUrl}/${id}/booking-legs`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  setPassengersForBookingLeg(bookingLegId: string, data: any): Observable<unknown> {
    return this.http.put<unknown>(`${this.bookingUrl}/booking-legs/${bookingLegId}/passengers`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  removePassengersFromBookingLeg(bookingLegId: string, data: any): Observable<unknown> {
    return this.http.delete<unknown>(`${this.bookingUrl}/booking-legs/${bookingLegId}/passengers`, { body: data })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }


  getBookings(opts = {}): Observable<Booking[]> {
    return this.http.get<Booking[]>(this.bookingUrl, {
      params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() })
    })
      .pipe(
        map(bookings => bookings.map(booking => {
          booking.bookingLegs.sort((a, b) => new Date(a.takeoffDate).getTime() - new Date(b.takeoffDate).getTime());
          return booking;
        })),
        tap(bookings => { 
          this.bookings$.next(bookings); 
        }),
        catchError(this.errorService.handleError)
      );
  }

  getAllBookings(query: { [ x: string ]: string | boolean; }): Observable<{ data: Booking[] | number, pagination?: Pagination }> {
    return this.http.get<{ data: Booking[] | number, pagination?: Pagination }>(`${this.bookingUrl}/master-tab`, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getMyBookings(query = {}): Observable<Booking[]> {
    return this.http.get<Booking[]>(`${this.bookingUrl}/my`, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        map(bookings => bookings.map(booking => {
          booking.bookingLegs.sort((a, b) => new Date(a.takeoffDate).getTime() - new Date(b.takeoffDate).getTime());
          return booking;
        })),
        tap(bookings => { 
          this.bookings$.next(bookings); 
        }),
        catchError(this.errorService.handleError)
      );
  }

  getBookingCurrentPage(id: string): Observable<{ data: Booking[] | number, pagination?: Pagination }> {
    return this.http.get<{ data: Booking[] | number, pagination?: Pagination }>(`${this.bookingUrl}/master-tab/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getBookingsCount(opts = {}): Observable<number> {
    return this.http.get<number>(this.bookingUrl, {
      params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() })
    })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  filterAndSortBookings(bookings: Booking[], order?: "asc" | "desc"): Booking[] {
    // "asc" - default sorting
    const sortingFunction = (order === 'desc')
      ? (a: Booking, b: Booking) => new Date(b.bookingLegs[0].takeoffDate).getTime() - new Date(a.bookingLegs[0].takeoffDate).getTime()
      : (a: Booking, b: Booking) => new Date(a.bookingLegs[0].takeoffDate).getTime() - new Date(b.bookingLegs[0].takeoffDate).getTime();

    return bookings
      .map(booking => {
        booking.bookingLegs.sort((a, b) => new Date(a.takeoffDate).getTime() - new Date(b.takeoffDate).getTime());
        return booking;
      })
      .filter(booking => booking.bookingLegs[0].takeoffDate)
      .sort(sortingFunction);
  }

  uploadAgreement(formData: FormData): Observable<string> {
    return this.http.post<string>(this.bookingUrl + `/agreement`, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  uploadAgreementSigned(formData: FormData): Observable<string> {
    return this.http.post<string>(this.bookingUrl + `/agreement-signed`, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  uploadInvoice(formData: FormData, bookingId: string): Observable<string> {
    return this.http.post<string>(this.bookingUrl + `/${bookingId}/invoice`, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  uploadPaymentConfirmation(formData: FormData, bookingId: string): Observable<string> {
    return this.http.post<string>(this.bookingUrl + `/${bookingId}/payment-confirmation`, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  uploadDocs(bookingId: string, formData: FormData): Observable<string[]> {
    return this.http.post<string[]>(this.bookingUrl + `/${bookingId}/documents`, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getDocument(link: string): Observable<Blob> {
    return this.http.get(link, { responseType: 'blob' })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  setStatus(bookingId: string, status: string): Observable<Booking> {
    return this.http.patch<Booking>(`${this.bookingUrl}/${bookingId}/status/${status}`, {});
  }

  getBookingUrl(): string {
    return this.bookingUrl;
  }

  getRefundAmount(bookingId: string): Observable<number> {
    return this.http.get<number>(`${this.bookingUrl}/${bookingId}/refund-amount`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  cancelBooking(data: { bookingId: string; comment: string, amountToRefund: number }): Observable<boolean> {
    const body = {
      action: 'cancelBooking',
      data
    };
    return this.http.post<boolean>(`${this.globalSharedService.config.baseUrl}/actions`, body)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  setLanded(data: { bookingId: string; comment: string }): Observable<boolean> {
    const body = {
      action: 'landBooking',
      data
    };
    return this.http.post<boolean>(`${this.globalSharedService.config.baseUrl}/actions`, body)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  deleteDocs(data: object): Observable<unknown> {
    return this.http.delete<unknown>(`${this.globalSharedService.config.baseUrl}` + `/file`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  addTags(bookingId: string, tags: string[]): Observable<Booking> {
    return this.http.post<Booking>(`${this.bookingUrl}/${bookingId}/tags`, tags)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  removeTags(bookingId: string, tags: number[]): Observable<unknown> {
    return this.http.delete<unknown>(`${this.bookingUrl}/${bookingId}/tags`, { body: tags })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createBooking(bids: string[]): Observable<unknown> {
    return this.http.post<unknown>(`${this.bookingUrl}`, { bids })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateBooking(id: string, data: { [ x: string ]: string }): Observable<unknown> {
    return this.http.patch<unknown>(`${this.bookingUrl}/${id}`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createStripePaymentIntent(bookingId: string): Observable<any> {
    return this.http.post<any>(`${this.bookingUrl}/${bookingId}/payment-intent`, {})
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  setFeePaid(bookingId: string): Observable<Booking> {
    return this.setStatus(bookingId, 'set-fee-paid');
  }

  unsetFeePaid(bookingId: string): Observable<Booking> {
    return this.setStatus(bookingId, 'unset-fee-paid');
  }

  confirmAgreement(bookingId: string): Observable<Booking> {
    return this.setStatus(bookingId, 'confirm-agreement');
  }

  confirmPayment(bookingId: string): Observable<Booking> {
    return this.setStatus(bookingId, 'confirm-payment');
  }

  confirmFlightReady(bookingId: string): Observable<Booking> {
    return this.setStatus(bookingId, 'confirm-flight-ready');
  }

  createChecklistFromDefault(id: string): Observable<unknown> {
    return this.http.put<unknown>(`${this.bookingUrl}/${id}/checklist?useDefault=true`, {})
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createChecklistFromScratch(id: string, data: { name: string, visible: boolean, isChecked: boolean }[]): Observable<unknown> {
    return this.http.put<unknown>(`${this.bookingUrl}/${id}/checklist`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
